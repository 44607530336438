* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/inter/InterRegular.ttf") format("truetype");
  font-weight: normal;
  descent-override: 0%;
  ascent-override: 80%;
}

input::placeholder {
  overflow: visible;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/inter/InterBold.ttf") format("truetype");
  font-weight: bold;
  descent-override: 0%;
  ascent-override: 80%;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/inter/InterLight.ttf") format("truetype");
  font-weight: lighter;
  descent-override: 0%;
  ascent-override: 80%;
}
